import React from 'react'
import tw, { css } from 'twin.macro'

import SEO from 'components/seo'
import Layout from 'components/layouts/layout'
import ContainedButton from 'components/buttons/contained-button'

import PdfApplication from 'static/franchise-application.pdf'

export default ({ location }) => {
  const steps = stepsData

  return (
    <Layout title="Information">
      <SEO title="Information" path={location.pathname} />

      <h2 className="px-4 bg-primary-900 text-white text-center py-1">
        STEPS TO OWNERSHIP
      </h2>

      <p className="text-primary-500 text-lg my-4 md:my-6 text-center">
        Apply <span className="font-bold">today!</span>
      </p>

      <ol
        className="px-4"
        css={[
          css`
            li div p {
              ${tw`mb-4`}
            }
          `,
        ]}
      >
        {steps.map(({ title, detailsHTML }, index) => (
          <li className="mb-8" key={title}>
            <h3 className="px-4 py-1 mb-4 bg-gray-400 font-bold">
              {`${index + 1}. ${title}`}
            </h3>
            <div className="px-4">{detailsHTML.map(detail => detail)}</div>
          </li>
        ))}
      </ol>

      <div className="my-8 md:my-12 flex flex-row justify-center items-center space-x-4">
        <a href={PdfApplication} target="__blank">
          <ContainedButton>DOWNLOAD FORM</ContainedButton>
        </a>
        <span>OR</span>
        <a href="/preliminary-application">
          <ContainedButton>BEGIN ONLINE APPLICATION</ContainedButton>
        </a>
      </div>

      <div className="mx-4 border-b border-gray-400"></div>
    </Layout>
  )
}

const stepsData = [
  {
    title: 'PRELIMINARY CONTACT AND FRANCHISE APPLICATION',
    detailsHTML: [
      <p key={1}>
        The first thing any potential franchisee does to show interest in how to
        start a Little Scholar School is to make preliminary contact with us,
        either by emailing us at{' '}
        <a href="mailto:info@littlscholar.eudcation" className="font-bold">
          info@littlescholar.education
        </a>{' '}
        or by contacting us directly at{' '}
        <a href="tel:+17248851136" className="font-bold">
          724-885-1136
        </a>
        . We will schedule an initial phone or Zoom conversation to provide you
        with more details about our opportunity and answer any questions you
        might have. This initial conversation is important because it allows us
        to get to know each other and to see if our values and goals align.
        After this preliminary conversation, you will complete our Little
        Scholar Franchise Application that we will share with our team for
        review and pre-approval. When considering your application, we will look
        at factors such as your location interest, total investment amount, and
        your experience, among other consideration, to decide whether
        franchising with us is a good idea.
      </p>,
    ],
  },
  {
    title: 'BUSINESS DEVELOPMENT CALL',
    detailsHTML: [
      <p key={1}>
        After we receive your completed franchise application, we will schedule
        a business development call where we will delve into your experience and
        qualifications for running a successful Little Scholar business. It is
        critical that we perform a thorough vetting to choose candidates we feel
        have the greatest potential to succeed as a Little Scholar franchisee.
        Of course, this is a two-way process, and we want to answer all of your
        questions so you can feel comfortable that you are making the right
        decision for your future.
      </p>,
    ],
  },
  {
    title: 'RECEIVING AND REVIEWING THE FRANCHISE DISCLOSURE INTENT DOCUMENT',
    detailsHTML: [
      <p key={1}>
        The Franchise Disclosure Document (FDD) of any franchisor is an
        important document that outlines in detail who and what the franchise is
        and what the franchisor/franchisee relationship entails. Our FDD is no
        different, and goes over important information, like what your initial
        and ongoing investment will be, and what our training and support of you
        looks like. FDDs are lengthy documents, which is why you will have two
        weeks to review ours. A thorough reading of our FDD will help you decide
        whether or not you would like to become part of the Little Scholar
        family.
      </p>,
    ],
  },
  {
    title: 'NON-DISCLOSURE AGREEMENT',
    detailsHTML: [
      <p key={1}>
        A confidentiality agreement, also called a nondisclosure agreement or
        NDA, requires the potential investor to not disclose or share any and
        all information that they gain during this inquiry phase of the
        franchising process. This contract creates a legal obligation to privacy
        and compels those who agree to keep any specified information top-secret
        or secured. Upon signing of the NDA, sample lesson plans and resources
        will be shared with the potential investor to help determine if Little
        Scholar is the franchise for them.
      </p>,
    ],
  },
  {
    title: 'RECEIVING AND REVIEWING THE FRANCHISE DISCLOSURE INTENT DOCUMENT',
    detailsHTML: [
      <p key={1}>
        If after review of all documents and samples, you decide you'd like to
        continue the process and start your career in education with us, you'll
        sign our Little Scholar Franchise Agreement - at which point, you will
        officially become a Little Scholar Franchisee! From there, our team
        members will work with you to launch Little Scholar in your country.
      </p>,
    ],
  },
]
